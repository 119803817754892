import "./WhatappEnquiry.scss";
import { FaWhatsapp } from "react-icons/fa";

const WhatsappEnquiry = () => {
  const handleWhatsapp = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=7411793154&text=Hello%20BlaBlaStay.",
      "_blank"
    );
  };

  return (
    <button className="whatsapp-floating-button" onClick={handleWhatsapp}>
      WhatsApp
      <FaWhatsapp size={24} className="whatsapp-icon" />
    </button>
  );
};

export default WhatsappEnquiry;
