import { useDispatch } from "react-redux";
import { FaSpinner } from "react-icons/fa";
import "./EnquiryButton.scss";
import EnquiryModal from "components/EnquiryModal/EnquiryModal";
import { sendEnquiry } from "store/actions";
import { Notification } from "shared-components/Notification";

type Props = {
  text: string;
  showLoader?: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  showEnquiryModal?: boolean;
  closeEnquiryModal?: () => void;
};

export function EnquiryButton({
  text,
  showLoader = false,
  className = "",
  disabled = false,
  showEnquiryModal = false,
  closeEnquiryModal = () => {},
  onClick,
}: Props) {
  const dispatch = useDispatch();

  const handleEnquiry = async (formData: {
    name: string;
    mobileNumber: string;
    emailAddress: string;
    notes: string;
  }) => {
    console.log("Enquiry Submitted:", formData);
    try {
      const response = await dispatch(sendEnquiry(formData));
      console.log("Enquiry Response:", response);
      Notification(
        "Enquiry Submitted",
        "Your enquiry has been submitted.\nWe will get back to you soon.",
        "success"
      );
    } catch (error) {
      console.error("Enquiry Submission Failed:", error);
    }
  };

  return (
    <>
      <button
        onClick={onClick}
        disabled={disabled}
        className={`form-buttons ${className}`.trim()}
      >
        {text} {showLoader && <FaSpinner size={20} className="icon spinner" />}
      </button>
      {showEnquiryModal && (
        <EnquiryModal
          isOpen={showEnquiryModal}
          onClose={closeEnquiryModal}
          onSubmit={handleEnquiry}
        />
      )}
    </>
  );
}
