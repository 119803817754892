import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { AiOutlineClose, AiOutlineMinusCircle } from "react-icons/ai";
import { BsPlusCircle } from "react-icons/bs";

import "./style.scss";
import { FormField } from "shared-components";

type Props = {
  showModal: boolean;
  showButton: boolean;
  setShowModal: (value: boolean) => void;
  maxGuest: number;
  capacity: number;
  handleGuests?: (action: string, type: string) => void;
  guests: {
    adult: number;
    children: number;
    infant: number;
    [key: string]: number;
  };
};

function EnquiryGuestsField(props: Props) {
  let maxGuest = Number(props.maxGuest);
  let capacity = Number(props.capacity);
  // console.log(maxGuest);
  // console.log(capacity);

  const { guests, handleGuests } = props;

  const guestsType = useMemo(
    () => [
      {
        heading: "Adults",
        description: "Ages 13 or above",
        value: guests.adult || 0,
        name: "adult",
      },
      {
        heading: "Children",
        description: "Ages 2-12",
        value: guests.children || 0,
        name: "children",
      },
      {
        heading: "Infants",
        description: "Under 2",
        value: guests.infant || 0,
        name: "infant",
      },
    ],
    [guests]
  );

  const totalGuests =
    (Number(guests.adult) || 0) + (Number(guests.children) || 0);
  let guestsString = totalGuests !== 0 ? totalGuests + " guests" : "";
  let infantString =
    Number(guests.infant) !== 0 ? guests.infant + " infant" : "";
  let text = "";

  if (guestsString) {
    text = guestsString + (infantString ? ", " + infantString : "");
  } else {
    if (infantString) {
      text = guestsString + infantString;
    }
  }

  return (
    <>
      <FormField
        disabled={!props.showButton}
        placeholder={"Add Guests"}
        value={text}
        label="Guests"
        name="guests"
        onClick={() => {
          if (props.showButton) props.setShowModal(true);
        }}
      />

      <div className="enquiry-guests-field">
        {props.showModal && (
          <div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <AiOutlineClose
                size={18}
                className="modal-close-icon mt-2"
                onClick={() => props.setShowModal(false)}
              />
            </div>
            {guestsType.map((guest, index) => {
              let maxValue = capacity > maxGuest ? capacity : maxGuest;
              if (guest.name === "infant") maxValue = 2; // keep it static

              return (
                <div key={index} className="guests-type-row">
                  <div>
                    <div className="heading">{guest.heading}</div>
                    <div className="description">{guest.description}</div>
                  </div>

                  <div className="guests-type-btn-box">
                    <div className="minus">
                      <AiOutlineMinusCircle
                        onClick={() => {
                          if (handleGuests) handleGuests("minus", guest.name);
                        }}
                        size={30}
                        color={guest.value === 0 ? "#DDDDDD" : "black"}
                      />
                    </div>
                    <div className="count">{guest.value || 0}</div>
                    <div className="plus">
                      <BsPlusCircle
                        size={27}
                        onClick={() => {
                          if (handleGuests) handleGuests("plus", guest.name);
                        }}
                        color={
                          (guest.name === "infant" &&
                            guest.value === maxValue) ||
                          (guest.name !== "infant" &&
                            guests.adult + guests.children === maxGuest &&
                            guests.adult > 0 &&
                            guests.children > 0)
                            ? "#DDDDDD"
                            : "black"
                        }
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}

export default EnquiryGuestsField;
