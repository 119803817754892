import "./style.scss";
import Images from "utilities/Images";

type Props = {
  flatId: string;
  showGroup: string;
  propertyMaster: any;
  subPropertyData: any[];
  handleGroupProperty: (hotel: any) => void;
  handleIndividualProperty: (hote: any) => void;
};

function SimilarHotels(props: Props) {
  return !props.subPropertyData.length ? (
    <div />
  ) : (
    <div className="mt-5 mb-5">
      <h4 className="mb-4">
        <strong>
          {props.showGroup === "0"
            ? "Property Type"
            : "Properties from same host"}
        </strong>
      </h4>
      {props.showGroup == "0" ? (
        <div className="row pl-5 pr-4">
          {props.subPropertyData.map((el: any, index: number) => {
            return (
              <div
                key={index}
                className={`col-lg-3 col-md-3 col-sm-6 col-xs-6 sub-type-card ${
                  props.flatId == el.subPropertyId && "active"
                }`}
                onClick={() =>
                  props.handleIndividualProperty(el.subPropertyName)
                }
              >
                <div className="sub-type-name">{el.name}</div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="horizontal-scroll-wrapper">
          {props.subPropertyData.map((el: any, index: number) => {
            // console.log("EL",el)
            return (
              <div
                key={index}
                className="hotel-item-container2"
                style={{ margin: "8px", height: "340px", borderRadius: "4px" }}
                onClick={() => props.handleGroupProperty(el.subPropertyId)}
              >
                <img
                  src={el.imageUrl || Images.noImage}
                  style={{ width: "240px", height: "140px" }}
                  className="hotel-item-image"
                  alt=""
                />

                <div className="hotel-item-description">
                  <div className="hotel-item-type-icon">
                    <div
                      className="hotel-item-type"
                      style={{ height: "44px", overflow: "hidden" }}
                    >
                      {el.subPropertyName}
                    </div>
                  </div>
                  <div
                    className="hotel-item-name"
                    style={{ height: "24px", overflow: "hidden" }}
                  >
                    {el.subPropertyNumber}
                  </div>
                  <div
                    className="hotel-item-name"
                    style={{ height: "24px", overflow: "hidden" }}
                  >
                    {el.sub_property_type}
                  </div>
                  <div className="hotel-item-type">
                    {props.propertyMaster.property_type}
                  </div>
                  <div
                    className="hotel-item-rooms-type"
                    style={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {el.sub_property_config}
                  </div>

                  <div className="hotel-item-rating-price">
                    <div className="hotel-item-price">
                      ₹ {el.dailyPrice || 0}{" "}
                      <span className="per-night">/ night</span>
                    </div>
                    <div className="hotel-item-rating">
                      <span className="star">{el.avgrating && "★"}</span>
                      <span className="rating">{el.avgrating}</span>
                      <span className="users">
                        {el.reviewcount && `(${el.reviewcount})`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default SimilarHotels;
