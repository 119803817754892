import React, { useEffect, useState } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import "./CallSupport.scss";
import { getLocationByMobile } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "helpers/GenericType";

const CallSupport: React.FC = () => {
  const { hotel }: any = useSelector((state) => state);
  const dispatch: AppDispatch = useDispatch();
  const [helplineNumber, setHelplineNumber] = useState("7411793154");

  useEffect(() => {
    const location = hotel.searchedLocation;
    // First, check if locationHelpline is already available
    // const locationHelpline = hotel?.searchedLocation?.helplineNumber;
    // if (locationHelpline) {
    //   setHelplineNumber(locationHelpline);
    //   return;
    // }

    dispatch(getLocationByMobile(location || "bang?")).then((res: any) => {
      if (res?.data?.length > 0) {
        const apiHelpline = res.data[0].helplineNumber;

        console.log("Helpline Numbers:", "API:", apiHelpline);

        setHelplineNumber(apiHelpline);
      }
    });
  }, [dispatch]);

  return (
    <div className="call-support">
      <a href={`tel:+${helplineNumber}`} className="call-button">
        <FaPhoneAlt className="call-icon" />
        <div className="call-text">
          <span>24/7 Call Support</span>
          <span className="call-number">{helplineNumber}</span>
        </div>
      </a>
    </div>
  );
};

export default CallSupport;
