import React, { useState } from "react";
import { FaQuestionCircle, FaWindowClose } from "react-icons/fa";
import "./EnquiryIcon.scss";
import { EnquiryButton } from "./EnquiryButton";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "helpers/GenericType";
import { changeActiveOption, sendEnquiry } from "store/actions";
import { Notification } from "shared-components/Notification";
import WhatappEnquiry from "./WhatappEnquiry";

const EnquiryIcon: React.FC = () => {
  const { user }: any = useSelector((state) => state);
  const dispatch: AppDispatch = useDispatch();
  const [isCardOpen, setIsCardOpen] = useState(false);

  const handleEnquiry = () => {
    console.log("enquiry");

    if (user.user) {
      const formData = {
        userId: user.user.id,
        name: user.user.fullName,
        mobileNumber: user.user.phoneNumber,
        emailAddress: user.user.emailAddress,
        notes: user.user.notes || "",
      };

      dispatch(sendEnquiry(formData));
      Notification(
        "Enquiry Submitted",
        "Your enquiry has been submitted.\nWe will get back to you soon.",
        "success"
      );
    } else {
      localStorage.setItem("enquiryLogin", "true");
      dispatch(changeActiveOption(1));
    }
  };

  return (
    <div className="enquiry-container">
      {/* Icon */}
      <div className="enquiry-icon" onClick={() => setIsCardOpen(!isCardOpen)}>
        {isCardOpen ? (
          <FaWindowClose size={24} />
        ) : (
          <FaQuestionCircle size={24} />
        )}
      </div>

      {/* Card with Enquiry Button */}
      {isCardOpen && (
        <>
          <div className="enquiry-card">
            <h4>Need Help?</h4>
            <p>Contact us for enquiry.</p>
            <EnquiryButton text="Request a call" onClick={handleEnquiry} />
            <WhatappEnquiry />
          </div>
        </>
      )}
    </div>
  );
};

export default EnquiryIcon;
