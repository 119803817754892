import { useState } from "react";
import styles from "./EnquiryModal.module.scss";
import { Modal } from "shared-components";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formData: {
    name: string;
    mobileNumber: string;
    emailAddress: string;
    notes: string;
  }) => void;
};

const EnquiryModal = ({ isOpen, onClose, onSubmit }: Props) => {
  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
    emailAddress: "",
    notes: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
    onClose();
  };

  return (
    <Modal
      visible={isOpen}
      heading="Request a Call Back"
      handleClose={onClose}
      containerClass={styles.modalContainer}
      className={styles.modal}
      headerClass={styles.modalHeader}
      bodyClass={styles.modalBody}
    >
      <form className={styles.modalContent} onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Name"
          required
          value={formData.name}
          onChange={handleChange}
          className={styles.inputField}
        />
        <input
          type="text"
          name="mobileNumber"
          placeholder="Mobile Number"
          required
          value={formData.mobileNumber}
          onChange={handleChange}
          className={styles.inputField}
        />
        <input
          type="email"
          name="emailAddress"
          placeholder="Email Address"
          required
          value={formData.emailAddress}
          onChange={handleChange}
          className={styles.inputField}
        />
        <textarea
          name="notes"
          placeholder="Notes"
          value={formData.notes}
          onChange={handleChange}
          className={styles.textarea}
        ></textarea>

        <button onClick={handleSubmit} className={styles.submitBtn}>
          Submit
        </button>
      </form>
    </Modal>
  );
};

export default EnquiryModal;
